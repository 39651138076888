/* imports */
@import url('https://fonts.cdnfonts.com/css/gotham-rounded');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');

html{
  overflow-x: hidden;

  /* background: linear-gradient(30deg,#7286ebc5 , #ff9fb4d2 60%); */
  }

/* Main  */

body {
  margin: 0;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  text-shadow: rgba(0,0,0,.01) 0 0 1px;
  overflow-x: hidden;
  background-color: #F2F2F2;
}


.container {
  display: flex;
  flex-direction: column;
  padding: 0 6em;
  box-sizing: border-box;
  max-width: 1440px;
  margin: auto;
  width: 100%;
  /* overflow-x: hidden; */
  
}


@media screen and (max-width: 1126px) {
  .container {
    padding: 0 4em;

    
  }
}

@media screen and (max-width: 961px) {
  .container {
    padding: 0 2em;

    
  }
}

@media screen and (max-width: 390px) {
  .container {
    padding: 0 1em;

    
  }
}

.sub-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: flex-start;
  
}

.sub-container img {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: 50% 0;
  height: 400px;
  border-radius: 10px;
}

.sub-container .cont-1 {
  width: 60%;
  padding-bottom: 1em;
}
.sub-container .cont-2 {
  width: 35%;
  padding-bottom: 1em;
}


@media screen and (max-width: 1126px) {

  .sub-container {
    flex-direction: column;
  }
  
  .sub-container .cont-1 {
    width: 100%;
  }
  .sub-container .cont-2 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sub-container img {
      width: 100%;
      height: auto;
      object-fit: cover;
      object-position: 50% 30%;
      height: 400px;
   }
  
}

.tmat-text {
  font-size: 36px;
  font-weight: bold;
  text-align: center;
}

.main {
  margin: auto;
  width: 100%;
  border-radius: 20px;
}

.main section {
  margin-top: 2.5em;
  min-height: 580px;
  position: relative;
  display: flex;
  flex-direction: column;
  opacity: 0;
  height: 100%;
  padding: 3em 0 3em 0;
}

.separator {
  width: 150px;
  height: 7px;
  background-color: #364D62;
  margin: 10px 0;
}



@media screen and (max-width: 626px) {
  .main section {
    padding: 1em 0 1em 0;
  }
}

@media screen and (max-width: 1126px) {
  .main section {
    padding: 2em 0 2em 0;
  }
}


section.fade-in {
  opacity: 1;
  transition: opacity 1s ease, transform 1s ease;
  transform: translateY(-2.5em);
}

section.fade-out {
  opacity: 0;
  transition: opacity 1s ease, transform 1s ease;
}

img {
  /* border-radius: 10px; */
}

/* default text style and decoration  */

h1 {
  margin: 0;
  font-size: 27.9px;
  color: #4B39B5;
  font-family: 'Poppins', sans-serif;

}

h2{
    font-weight: 200;
    
    color: #4B39B5;
}

p {
  font-family: 'Poppins', sans-serif;
  color: #2c2c2c;
  font-weight: 500;
  font-size: 1.2em;
}

/* navbar */

header {
  background: black;
}

header h1 {
  font-size: 40px;
  margin-bottom: 1px;
  color: #ffffff;
  font-family: 'Gotham Rounded', sans-serif;
}
header .logo {
  position: absolute;
  top: 0;
  padding-left: 30px;
  padding-top: 20px;
  z-index: 1;
}

header .logo img {
  position: absolute;
  width: auto;
  height: 40px;
  margin-top: 8px;
}

header .logo a {
text-decoration: none;
}

.navbar {
  z-index: 9;
  position: fixed;
  width: 100%;
  padding-top: 20px;
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 62px;
}

.navbar .nav-container li {
  list-style: none;
}

.navbar .nav-container a {
  text-decoration: none;
  color: #0e2431;
  font-weight: 500;
  font-size: 2.0rem;
  padding: 0.7rem;
}

.navbar .nav-container a:hover{
    font-weight: bolder;
}

.nav-container {
  display: block;
  position: relative;
  height: 60px;
}

.nav-container .checkbox {
  position: absolute;
  display: block;
  height: 32px;
  width: 32px;
  top: 12px;
  right: 20px;
  z-index: 5;
  opacity: 0;
  cursor: pointer;
}

.nav-container .hamburger-lines {
  display: block;
  height: 26px;
  width: 32px;
  position: absolute;
  top: 17px;
  right:20px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.nav-container .hamburger-lines .line {
  display: block;
  height: 4px;
  width: 100%;
  border-radius: 10px;
  background: #0e2431;
}

.nav-container .hamburger-lines .line1 {
  transform-origin: 0% 0%;
  transition: transform 0.4s ease-in-out;
}

.nav-container .hamburger-lines .line2 {
  transition: transform 0.2s ease-in-out;
}

.nav-container .hamburger-lines .line3 {
  transform-origin: 0% 100%;
  transition: transform 0.4s ease-in-out;
}

.navbar .menu-items {
  top: -1em;
  position: fixed;
  background: white;
  /* box-shadow: inset 0 0 2000px rgba(255, 255, 255, .5); */
  height: 100vh;
  width: 100%;
  transform: translate(150%);
  display: flex;
  flex-direction: column;
  transition: transform 0.5s ease-in-out;
  text-align: center;
  justify-content: center;
  padding: 0;
  z-index: 1;
}

.navbar .menu-items li {
  margin-bottom: 1.2rem;
  font-size: 1.5rem;
  font-weight: 500;
}

.navbar .menu-items ul {
 padding: 0;
}

.nav-container input[type="checkbox"]:checked ~ .menu-items {
  transform: translateX(0);
  transform: translateY(1em);
}

.nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
  transform: rotate(45deg);
}

.nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
  transform: scaleY(0);
}

.nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
  transform: rotate(-45deg);
}

.nav-container input[type="checkbox"]:checked ~ .logo{
  display: none;
}

/* form input bar  */


.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.form-wrap {
  background: white;
  padding: 8px;
  border-radius: 30px;
  max-width: 700px;
  width: 100%;
  margin: auto;
}

form {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 8px;
  position: relative;
}

form input {
  width: 100%;
  padding: 5px;
  border: 0px;
  height: 30px;
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}

form .input-bar {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  width: 100%;
}

form button {
  position: absolute;
  right: 0;
  width: 120px;
  border: 0px;
  background: orange;
  border-radius: 30px;
  cursor: pointer;
  color: white;
  font-weight: 600;
  height: 72px;
  top: -8px;
  overflow: hidden; /* To clip the shining effect within the button boundaries */
}


.submit-button {
  display: block;
  margin: 0 auto;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 14px;
}

form input {
  opacity: 0;
  animation: fade-in 1s forwards;
  outline: none; /* Removes the outline that appears when focused */
  border: none;  /* Removes the border that appears when focused */
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#Hero .submit-box h2{
  color: black;
  font-size: 1.5rem;
}

@media screen and (max-width: 961px) {
  form input {
    font-size: 11px;
  }
  
  
}

@media screen and (max-width: 480px) {
form button {
  position: absolute;
  right: 0;
  width: 80px;
  border: 0px;
  background: orange;
  border-radius: 30px;
  cursor: pointer;
  color: white;
  font-weight: 600;
  height: 72px;
  top: -8px;
}
}



/* Hero section */

#Hero {
  background: linear-gradient(90deg,#0051ffa6 , #02eeff86 95%);
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 90vh;
  z-index: 0;
  position: relative;
  flex-direction: row-reverse;
}


#Hero .container {
  text-align: center;
  margin: 0 0 80px 0;
  background: none;
  width: auto;
}


#Hero .container-rocket {
  text-align: center;
  margin: 60px 0 -40px 0;
  background: none;
  animation: fade-in 1s forwards;
}


#Hero .container-rocket .rocket img{
  height: auto; 
  width: auto; 
  max-width: 100%; 
  max-height: 650px;
  position: relative;
  bottom: 50px;
}

#Hero h2 {
  font-size: 47.9px;
  margin: 0;
  color: #ffffff;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
}

#Hero h3 {
  font-size: 27.9px;
  color: #ffffff;
  padding-top: 0;
}

#Hero p{
margin: auto;
padding-top: 1em;
padding-bottom: 2em;
}

#Hero .container a {
  text-decoration: none;
}

@media screen and (max-width: 1419px) {
  #Hero {
    flex-direction: column;
  }
  
}



@media screen and (max-width: 961px) {

  #Hero h1 {
    font-size: 130px;
    margin-bottom: 1px;
    color: #ffffff;
    font-family: 'Gotham Rounded', sans-serif;
  }
  
  #Hero h2 {
    font-size: 37.9px;
  }

  #Hero h3 {
    font-size: 27.9px;
  }

  #Hero .logo img {
    position: absolute;
    width: auto;
    height: 120px;
    margin-top: 27px;
  }

  #Hero .logo {
    margin-right: 80px;
  }
  
}

@media screen and (max-width: 575px) {

  #Hero h1 {
    font-size: 80px;
    margin-bottom: 1px;
    color: #ffffff;
    font-family: 'Gotham Rounded', sans-serif;
  }
  
  #Hero h2 {
    font-size: 27.9px;
  }

  #Hero h3 {
    font-size: 17.9px;
  }

  #Hero .logo img {
    position: absolute;
    width: auto;
    height: 80px;
    margin-top: 17px;
  }

  #Hero .logo {
    margin-right: 80px;
  }
  
}


#Hero-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 10vh;
  padding-bottom: 1em;

}

#Hero-2 h1 {
  font-weight: 500;
  font-size: 1em;
  margin-bottom: 1px;
  letter-spacing: .2rem;
  font-stretch: expanded;
  transform: scale(1, 1.1);
}



@media screen and (max-width: 480px) {
  
  #Hero h1 {
    font-size: 60px;
    margin-bottom: 1px;
    color: #ffffff;
    font-family: 'Gotham Rounded', sans-serif;
  }
  
  #Hero h2 {
    font-size: 27.9px;
  }

  #Hero h3 {
    font-size: 17.9px;
  }

  #Hero .logo img {
    position: absolute;
    width: auto;
    height: 70px;
    margin-top: 7px;
  }

  #Hero .logo {
    margin-right: 50px;
  }
  
}

/* about section */

#About {
  
}

#About h2 {
  font-weight: 500;
  font-size: 27.9px;
  color: #364D62;
  font-family: 'Poppins', sans-serif;
}

#About h3 {
  font-weight: 200;

}

#About h4 {
  font-style: italic;
  color: #B151BE;
  font-weight: 600;
margin: 0;
}

#About h2, #About h3  {
  margin: 0;
}


/* footer */
footer {
  color: white;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: linear-gradient(30deg,#6999ffa6 , #ff9fb4d2 60%); */
  background: linear-gradient(90deg,#0051ffa6 , #02eeff86 95%);
  font-size: 20.9px;
}

/* solutions section  */
.graph {
  margin: 20px;
}
.graph-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  font-size: 50px;
  text-align: center;
  color: #4B39B5;
  padding-bottom: 1em;
}

.graph-container p {
  font-size: 17px;
  color: #4B39B5;
  border-top: 1px solid #4c39b557; /* Add a 1px solid border on top */
  padding-top: 25px; /* Add some padding to separate the border from text */
}

.labelName {
  font-size: 2rem;
  margin-top: -100px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}

@media screen and (max-width: 1126px) {

  .graph-container {
    flex-direction: column;
  }
  
}

@media screen and (max-width: 280px) {

  .graph {
    width: 280px;
  }
  
}



/* phase 0 page  */

#Phase0page {
  margin: auto;
  padding: 0 6em;
}

#Phase0page .container {
  border: 1px solid rgb(255, 255, 255);
  border-radius: 10px;
  padding: 3.5em;
  background-color: white;
  box-shadow: 5px 5px rgb(0 0 0 / 2%);
  max-width: 1280px;
}


#Phase0page .sub-container {
display: flex;
margin: auto;
width: 100%;
justify-content: center;
}

#Phase0page .cont-1 {
  width: 100%;
  text-align: center;
  max-width: 900px;
  }

#Phase0page .cont-2 {
  width: 100%;
  text-align: center;
  }

  .vertical-line {
  border-left: 2px solid #4B39B5;
  height: 40px;
  top: 0;
  transform: translateX(50%);
  }


.rocket-container {
  display: flex;
  width: auto;
  height: 400px;
  align-items: flex-end;
  flex-direction: row;
  margin-bottom: 40px;
  border-radius: 10px;
}

.rocket-title {
  position: relative;
  padding: 10px;
  color: white;
  font-size: 27px;
  font-weight: 500;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  -webkit-transform: scale(1, 1.3);
  -moz-transform: scale(1, 1.3);
  -o-transform: scale(1, 1.3);
  transform: scale(1, 1.3);
  border-top: 5px solid #ffffff;
  margin: 0 20px;
}

@media screen and (max-width: 961px) {

  #Phase0page {
    padding: 1em;
  }

  #Phase0page .sub-container {
    flex-direction: column;
  }
  
  #Phase0page [class^="cont-"] {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 2em;
  }
  
  #Phase0page .sub-container [class^="cont-"] h2,
  #Phase0page .sub-container [class^="cont-"] p {
    width: 100%;
    padding: 0.5em;
    padding-bottom: 2em;
    margin: 0;
  }

}

@media screen and (max-width: 961px) {
  #Phase0page .container {
    padding: 2em;
  
  }

}

@media screen and (max-width: 390px) {
  #Phase0page {
    padding: 0.5em;
  }

}

@media screen and (max-width: 961px) {

  .rocket-container {
    height: 200px;
  }
  .rocket-title {
    font-size: 20px;
  }
  
}

@media screen and (max-width: 1620px) {

  /* .rocket-container {
    height: 350px;
  } */
  
}

@media screen and (max-width: 1126px) {

  .rocket-container {
    height: 250px;
  }
  
}

@media screen and (max-width: 480px) {

  /* .rocket-container {
    height: 200px;
  } */
  .rocket-title {
    font-size: 0.8em;
  }
  
}

/* Advantage */

#Advantage {
  margin: auto;
  padding: 0 6em;
}

#Advantage .container .tmato-mini {
 display: flex;
 justify-content: space-between;
}


#Advantage .container .tmato-mini img{
  width: auto;
  height: 90px;
  transform: rotate(18deg);
  margin-right: -20px;
  
  position: sticky;
}

@media screen and (max-width: 359px) {

  #Advantage .container .tmato-mini img{
    display: none;
  }
  
}

#Advantage .container {
  border: 1px solid rgb(255, 255, 255);
  border-radius: 10px;
  padding: 3.5em;
  background-color: white;
  box-shadow: 5px 5px rgb(0 0 0 / 2%);
  max-width: 1280px;
}

#Advantage .sub-container {
  flex-direction: column;
}

#Advantage [class^="cont-"] {
  width: 100%;
  display: flex;
  align-items: flex-start;
}

#Advantage .sub-container [class^="cont-"] h2,
#Advantage .sub-container [class^="cont-"] p {
  width: 50%;
  padding: 0.5em;
}

#Advantage .sub-container [class^="cont-"] p {
  border-bottom: 1px solid #4B39B5;
  padding-bottom: 2em;
}


@media screen and (max-width: 961px) {

  #Advantage {
    padding: 1em;
  }

  #Advantage .sub-container {
    flex-direction: column;
  }
  
  #Advantage [class^="cont-"] {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 2em;
  }
  
  #Advantage .sub-container [class^="cont-"] h2,
  #Advantage .sub-container [class^="cont-"] p {
    width: 100%;
    padding: 0.5em;
    padding-bottom: 2em;
    margin: 0;
  }

}

@media screen and (max-width: 961px) {

  #Advantage .container {
    padding: 2em;
  
  }

}

@media screen and (max-width: 390px) {
  #Advantage {
    padding: 0.5em;
  }

}



/* Market */
#Market .sub-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: nowrap;
}

#Market .sub-container img {
  width: auto;
}

#Market .container h1 {
padding-top: 1em;
  color: #4B39B5;
}

#Market .container h2 {
  font-weight: 200;
  padding-bottom: 2em;
  color: #4B39B5;
  font-size: 28px;
}

#Market .sub-container .cont-1,
#Market .sub-container .cont-2 {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  padding-bottom: 2em;
}

#Market .sub-container .cont-content {
  display: flex;
  flex-direction: column;
  padding: 2em;
}

#Market .sub-container .cont-1 h1,
#Market .sub-container .cont-2 h1 {
  width: 100%;
}

@media screen and (max-width: 1419px) {
  #Market .sub-container {
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 961px) {
  #Market .container h1 {
    width: auto;
    font-size: 28px;
  }
  #Market .sub-container img {
    height: 300px;
  }


  #Market .sub-container p {
    padding: 0;
  } 
  
  #Market .sub-container .cont-1 h1,
  #Market .sub-container .cont-2 h1 {
    font-size: 22px;
  } 
  

}

@media screen and (max-width: 626px) {
  #Market .sub-container .cont-1,
  #Market .sub-container .cont-2 {
    flex-direction: column; 
  } 
  #Market .sub-container img {
    width: 100%;
    height: 400px;
  }
  #Market .sub-container .cont-content {
    padding: 0em;
  }
}

/* imagegallery */
#ImageGallery .slick-prev {
  left: -52px !important;
}
#ImageGallery .slick-next:before,
#ImageGallery .slick-prev:before {
  content: "" !important;
}
#ImageGallery .next-slick-arrow,
#ImageGallery .prev-slick-arrow {
  color: #000000;
  font-size: 45px;
}


#ImageGallery .image-gallery-container {
  max-width:100%;
}

#ImageGallery .image-gallery-container .description{
  width: 95%;
}

#ImageGallery .slick-slide:focus {
  outline: none;
}

#ImageGallery .slick-slide img {
  width: 95%;
  height: 400px;
  object-fit: cover;
  border-radius: 10px;
}

#ImageGallery .slick-slide img:hover {
  cursor: grab;
}


#ImageGallery .arrows-container {
  display: flex;
  justify-content: flex-end;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

#ImageGallery .arrow {
  position: relative;
  font-size: 30px;
  margin: 10px;
  border-radius: 50%;
  border: 2px solid #000000;
  padding: 20px;
  width: 18px;
  height: 14px;
  transition: background-color 0.3s ease; /* Add a smooth transition for background-color change */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

#ImageGallery .arrow::before {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  background-color: transparent;
  border-radius: inherit;
  transition: background-color 0.3s ease;
  z-index: -1;
}

/* Add hover styles */
#ImageGallery .arrow:hover {
  cursor: pointer;
  color: white;
  background-color: #000000; /* Change the background to black on hover */
}

#ImageGallery .arrow div {
position: relative;
top: -15px;
}

#ImageGallery .slick-list {
  padding-top: 2em;
display: block;
overflow: visible!important;
}

#ImageGallery .h-overflow {
  overflow-x: visible!important
}


/* Faq */

#Faq h1 {
  text-align: center;
}

#Faq .sub-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

#Faq .cont-1 {
  background-color: #f7f7f7;
  border: 1px solid #ccc;
  margin: 10px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.6s;
  padding: 1em 0 1em 0;
  width: 95%;
}

#Faq .cont-1.open {
  background-color: #f7f7f7;
}

#Faq .faq-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
  margin: 0;
}

#Faq .arrow {
  transform: rotate(90deg);
  font-size: 20px;
  transition: transform 0.5s;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

#Faq .arrow.open {
  transform: rotate(270deg);
}

#Faq .answer-container {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}

#Faq .answer-container .answer {
  padding: 10px 20px;
}

#Faq .cont-1.open .answer-container {
  max-height: 200px; /* Adjust the max-height value as needed */
  transition: max-height 0.5s ease-in-out;
}
